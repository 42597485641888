module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rod's Surfboard","short_name":"Rod's","description":"Rod's Surfboard - Surf shop and school in Carcans","lang":"en","display":"standalone","icon":"src/images/favicon/android-chrome-512x512.png","start_url":"/","background_color":"#0ea5e9","theme_color":"#fff","localize":[{"start_url":"/de/","lang":"de","name":"Rod's Surfboard - Surf shop & school","short_name":"Rods","description":"Rod's Surfboard - Surfshop und Schule in Carcans"},{"start_url":"/en/","lang":"en","name":"Rod's - Surf shop & school","short_name":"Rods","description":"Rod's Surfboard - Surf shop und school in Carcans"},{"start_url":"/fr/","lang":"fr","name":"Rod's - magasin de surf & école","short_name":"Rods","description":"Rod's Surfboard - Surf shop et école à Carcans"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fbe3d62bfb370344137ce50f7308fc34"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
